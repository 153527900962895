import React from 'react';
import {Link} from 'react-router-dom';
import UserCinemaNav from './UserCinemaNav';

class NowPageSidebar extends React.Component {
  render() {
    return (
        <React.Fragment>
          <UserCinemaNav/>
          <div className="border-bottom">
            <h3 className="h5 pl-5 pt-5 text-secondary mb-0">Películas que comienzan en:</h3>
            <div className="o-btn-group">
              <div className="p-5 pr-0">
                {this.renderTimeButtons()}
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }

  renderTimeButtons() {
    const result = [];

    for (let i = 30; i <= 180; i = i + 30) {
      result.push(<Link to={this.props.baseUrl + '/minutos-' + i} className={'o-btn-group__item btn shadow ' + (i === this.props.timeLimit ? 'btn-primary' : 'btn-white')}>{i}'</Link>);
    }

    return result;
  }
}

export default NowPageSidebar;