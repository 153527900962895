import React from 'react';
import LinkButton from './LinkButton';
import MovieDetailsBlock from './MovieDetailsBlock';
import {getYouTubeEmbedUrl} from '../utils/misc';

class BillboardMovieDetailsBlock extends React.Component {
  constructor(props) {
    super(props);

    this.onExpandedToggle = this.onExpandedToggle.bind(this);

    this.state = {
      infoExpanded: false,
    };
  }

  onExpandedToggle() {
    this.setState(prevState => ({infoExpanded: !prevState.infoExpanded}));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.movie.id !== nextProps.movie.id
        || this.props.trailerOpen !== nextProps.trailerOpen
        || this.state.infoExpanded !== nextState.infoExpanded;
  }

  render() {
    const {movie} = this.props;
    const youtubeUrl = getYouTubeEmbedUrl(movie);

    return (
        <React.Fragment>
          <div className="d-md-flex justify-content-md-between mb-4">
            <h3 className="h5">{movie.name}</h3>
            <LinkButton className="mt-1" onClick={this.onExpandedToggle}>
              Info película <i className={'fal ' + (this.state.infoExpanded ? 'fa-angle-up' : 'fa-angle-down')}></i>
            </LinkButton>
          </div>

          {this.state.infoExpanded && (
              <div className="pb-4 fade-in">
                <MovieDetailsBlock movie={movie}/>
              </div>
          )}

          {youtubeUrl && this.props.trailerOpen && (
              <div className="py-4 border-top fade-in">
                <div className="d-flex mb-3">
                  <p className="h6 text-secondary mb-0">Trailer</p>
                  <LinkButton onClick={this.props.onTrailerClose} className="text-muted ml-auto"><i className="fal fa-times"></i></LinkButton>
                </div>

                <div className="embed-responsive embed-responsive-16by9 u-rounded-mask">
                  <iframe className="embed-responsive-item"
                          src={youtubeUrl}
                          allowFullScreen
                          title={'Trailer de ' + movie.name}
                  ></iframe>
                </div>
              </div>
          )}
        </React.Fragment>
    );
  }
}

export default BillboardMovieDetailsBlock;