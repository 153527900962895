import React from 'react';
import styled from 'styled-components';
import {getDateFilterDisplayString} from '../utils/showtimes';

const DatesScroller = styled.div`
  white-space: nowrap;
  overflow-x: auto;
`;

class BillboardSidebarDates extends React.Component {
  constructor(props) {
    super(props);

    this.onDateSelect = this.onDateSelect.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.selectedDate !== nextProps.selectedDate
        || this.props.dates.join(',') !== nextProps.dates.join(',');
  }

  onDateSelect(e) {
    e.preventDefault();
    const date = e.target.getAttribute('data-date');

    this.props.onDateSelect(date);
  }

  render() {
    const {dates} = this.props;

    if (!dates || !dates.length) {
      return '';
    }

    return (
        <div className="border-top">
          <div className="o-btn-group">
            <DatesScroller className="px-5 py-4">
              {dates.map(date => {
                const dateYmd = date.replace(/-/g, '');
                return (
                    <a
                        key={dateYmd}
                        data-date={dateYmd}
                        href={this.props.baseUrl + 'fecha-' + dateYmd}
                        className={'o-btn-group__item btn ' + (date === this.props.selectedDate ? 'btn-primary' : 'btn-white shadow')}
                        onClick={this.onDateSelect}
                    >{getDateFilterDisplayString(date)}</a>
                );
              })}
            </DatesScroller>
          </div>
        </div>
    );
  }
}

export default BillboardSidebarDates;