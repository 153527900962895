import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const MapContainer = styled.div`
  position: relative;
  min-height: 500px;
`;

const MapTooltip = styled.div`
  background: #fff;
  border: 1px solid #d9dde0;
  bottom: 50%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  position: absolute;
  padding: 1.5rem 1.5rem 3rem;
  line-height: 1.5;
  left: 50%;
  transform: translate3d(-50%, -30px, 0);
  width: 250px;
`;

const Title = styled.h1`
  font-size: 1rem !important;
  font-weight: 700;
`;

const Address = styled.p`
  color: #6c757d;
  font-size: ${13 / 16}rem !important;
  line-height: 1.5;
`;

const Map = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;

const ActionLink = styled.a`
  font-size: ${13 / 16}rem;
`;

const MapLink = styled.a`
  font-size: ${13 / 16}rem;
`;

const MapMarker = styled.img`
  bottom: -30px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
`;

const MapPosition = props => {
  return (
    <MapContainer>
      <MapTooltip>
        <Title>{props.name}</Title>

        <Address>{props.address}</Address>

        <p className="mb-2 text-center">
          <ActionLink
            className="btn btn-primary btn-sm"
            href={props.actionLinkUrl}
          >
            {props.actionLinkText}
          </ActionLink>
        </p>

        <p className="mb-0 text-center">
          <MapLink
            className="btn btn-default btn-sm"
            href={props.mapLinkUrl}
            title={props.mapLinkTextAlt}
            target="_blank"
          >
            {props.mapLinkText}
          </MapLink>
        </p>
        <MapMarker src={props.mapMarker} />
      </MapTooltip>

      <Map src={props.mapImage} alt={props.address} />
    </MapContainer>
  );
};

MapPosition.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  mapImage: PropTypes.string.isRequired,
  actionLinkUrl: PropTypes.string.isRequired,
  actionLinkText: PropTypes.string,
  mapLinkUrl: PropTypes.string.isRequired,
  mapLinkText: PropTypes.string,
  mapLinkTextAlt: PropTypes.string
};

MapPosition.defaultProps = {
  actionLinkText: "Ver Cartelera",
  mapLinkText: "Abrir en Mapas",
  mapLinkTextAlt: "Abrir en Google Maps"
};

export default MapPosition;
