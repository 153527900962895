import React from 'react';
import LinkButton from './LinkButton';

class BillboardMoviePoster extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.movie.poster_small !== nextProps.movie.poster_small;
  }

  render() {
    return (
        <LinkButton onClick={this.props.onTrailerToggle} className="o-video mb-3">
          <img className="o-video__image" src={this.props.movie.poster_small} alt=""/>
        </LinkButton>
    );
  }
}

export default BillboardMoviePoster;