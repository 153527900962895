import React from 'react';
import styled from 'styled-components';

const Option = styled.span`
  cursor: pointer;
`;

const PaymentOptionButton = props => (
    <li className="c-option-list__item">
      <Option
          className={'c-option-list__link d-flex align-items-center justify-content-between ' + (props.selected ? 'is-active' : '')}
          onClick={props.onClick}
      >
        {props.children}
      </Option>
    </li>
);

export default PaymentOptionButton;